import { render, staticRenderFns } from "./PlushiesListPage.vue?vue&type=template&id=4a6d7ee9&scoped=true&"
import script from "./PlushiesListPage.vue?vue&type=script&lang=ts&"
export * from "./PlushiesListPage.vue?vue&type=script&lang=ts&"
import style0 from "./PlushiesListPage.vue?vue&type=style&index=0&id=4a6d7ee9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a6d7ee9",
  null
  
)

export default component.exports