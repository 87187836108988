
















































































































































































import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import {
  ColumnFilterDropdownOption,
  RowClickEventData,
  ServerResponse,
} from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import PlushieAssignedAlertsList from "@/modules/plushie-alerts/components/AssignedAlertsList.vue";
import FactoryRestrictedModeAlerts from "@/modules/factory-restricted-mode/components/RestrictedModeAlerts.vue";
import QueueControlWidget from "@/modules/strict-capacity-mode/components/QueueControlWidget.vue";
import ReadyForProductionMassActionWarning from "@/modules/strict-capacity-mode/components/ReadyForProductionMassActionWarning.vue";
import PlushiesGridExportAction from "@/modules/document-export/components/PlushiesGridExportAction.vue";

import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import dataStore from "@/store";
import AlertStore from "@/modules/plushie-alerts/store";
import FactoryStore from "@/modules/factory/store";
import FileStorageStore from "@/modules/file-storage/store";
import UiConfigurationStore from "@/modules/ui-configuration/store";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import {
  FieldOrderAsc,
  FieldOrderDesc,
} from "@/modules/api/field-order-options";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import BasicRepository from "@/modules/api/basic.repository";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";
import { Dictionary } from "@/lib/Dictionary.type";
import GeneralListPageMixin from "@/lib/mixins/GeneralListPage";
import { Resource } from "@/modules/account/resource";
import BulkExportAsExcelService from "@/modules/document-export/bulk-export-as-excel.service";
import { MetaRoleValue } from "@/modules/account/meta-role.value";
import AccountStore from "@/modules/account/store";
import truncate from "@/lib/filters/truncate";

import MassActionsCard from "./MassActionsCard.vue";

import PlushieStore from "../store";
import PlushieFactorySummary from "../plushie-factory-summary.model";
import PlushieStatus from "../plushie-status.model";
import PlushieSummary from "../plushie-summary.model";
import AvailableMassActionsProviderService from "../available-mass-actions-provider.service";
import { ProductValue } from "../product.value";
import { PlushieStatusValue } from "../plushie-status.value";
import Plushie from "../plushie.model";

interface TableRow {
  id: string;
  storeItemId: string;
  orderNumber: string;
  orderDate: string;
  artwork?: string;
  quantity: number;
  extras: string;
  alerts?: string;
  factory: string;
  dueDate?: string;
  delay?: string;
  product: string;
  productId: string;
  priority: number;
  reviewer?: string;
  responsibleUser?: string;
  notes?: string;
}

@Component({
  metaInfo() {
    const component = this as PlushiesListPage;

    return {
      title: component.title,
    };
  },
  components: {
    FactoryRestrictedModeAlerts,
    MassActionsCard,
    PlushieAssignedAlertsList,
    PlushiesGridExportAction,
    QueueControlWidget,
    ReadyForProductionMassActionWarning,
  },
})
export default class PlushiesListPage extends mixins(GeneralListPageMixin) {
  private static readonly ARTWORK_SIZE = 150;

  @Prop({ required: true })
  public readonly statusId!: PlushieStatusValue;

  @Prop({ required: false })
  public readonly reviewer!: string;

  @Prop({ required: false })
  public readonly responsibleUser: string | undefined;

  @Inject("AvailableMassActionsProviderService")
  private fAvailableMassActionsProvider!: AvailableMassActionsProviderService;

  @Inject("PlushieSummaryRepository")
  private fPlushieSummaryRepository!: BasicRepository<PlushieSummary>;

  @Inject("PlushieFactorySummaryRepository")
  private fPlushieFactorySummaryRepository!: BasicRepository<PlushieFactorySummary>;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("window")
  private fWindow!: Window;

  public selectedPlushies: string[] = [];

  private fShowSelectedItemsOnly = false;
  private fIsUpdatingTableParams = false;
  private fLastSelectedItem?: string;
  private fRowsIdsList: string[] = [];

  private fStatusName?: string;
  private fAlertDataStore: AlertStore;
  private fFactoryDataStore: FactoryStore;
  private fFileStorageDataStore: FileStorageStore;
  private fPlushieDataStore: PlushieStore;
  private fAccountDataStore: AccountStore;
  private fUiConfigurationDataStore: UiConfigurationStore;

  private fIsMassActionAvailable = false;
  private fPulledToQueuePlushiesIds: string[] | undefined = undefined;

  get canViewRestrictedModeAlerts(): boolean {
    const user = this.getCurrentUser();

    if (user.role !== AuthenticatedUser.ROLE_FACTORY) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_RESTRICTED_MODE_ALERT_VIEW
    );
  }

  get canViewStrictCapacityModeControls(): boolean {
    const user = this.getCurrentUser();

    if (user.role !== AuthenticatedUser.ROLE_FACTORY) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_STRICT_CAPACITY_MODE_VIEW
    );
  }

  get showReadyForProductionMassActionWarning(): boolean {
    return (
      this.isFactoryView &&
      this.statusId === PlushieStatusValue.READY_FOR_PRODUCTION
    );
  }

  get displayArtworksCtrlText(): string {
    return this.fUiConfigurationDataStore.plushieListDisplayImages
      ? "Hide artworks"
      : "Display artworks";
  }

  get shouldDisplayArtworks(): boolean {
    if (
      this.isFactoryView &&
      this.statusId === PlushieStatusValue.READY_FOR_PRODUCTION
    ) {
      return false;
    }

    return this.fUiConfigurationDataStore.plushieListDisplayImages;
  }

  get shouldDisplayArtworksDisplayControl(): boolean {
    if (!this.isFactoryView) {
      return true;
    }

    return this.statusId !== PlushieStatusValue.READY_FOR_PRODUCTION;
  }

  get statusName(): string | undefined {
    return this.fStatusName;
  }

  get isBatchStatus(): boolean {
    return PlushieStatus.BATCH_STATUSES.includes(this.statusId);
  }

  get isMassActionAvailable(): boolean {
    return this.fIsMassActionAvailable;
  }

  get isFilteredByRelatedUser(): boolean {
    return this.isFilteredByResponsibleUser || this.isFilteredByReviewer;
  }

  get isFilteredByResponsibleUser(): boolean {
    return this.responsibleUser !== undefined;
  }

  get isFilteredByReviewer(): boolean {
    return this.reviewer !== undefined;
  }

  get selectedPlushiesCount(): number {
    return this.selectedPlushies.length;
  }

  get title(): string {
    if (this.fStatusName === undefined) {
      return "Plushies List";
    }

    if (!this.isFilteredByReviewer && !this.isFilteredByResponsibleUser) {
      return this.fStatusName;
    }

    return `My ${this.fStatusName} Plushies`;
  }

  get showPlushiesGridExportAction(): boolean {
    return !!this.filterForRequest && !!this.orderForRequest;
  }

  public constructor() {
    super();

    this.fAlertDataStore = getModule(AlertStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fUiConfigurationDataStore = getModule(UiConfigurationStore, dataStore);
    this.fAccountDataStore = getModule(AccountStore, dataStore);

    this.fColumns = [
      {
        name: "selection",
        header: "",
      },
      {
        name: "storeItemId",
        header: "SKU",
        isFilterable: true,
      },
      {
        name: "orderNumber",
        header: "Order",
        isFilterable: true,
        displayConstraint: "min_desktop",
        cssClass: "-hide-for-factory",
      },
      {
        name: "orderDate",
        header: "Order Date",
        isSortable: true,
        sortKey: "createdAt",
        displayConstraint: "min_desktop",
        cssClass: "-hide-for-factory",
      },
      {
        name: "artwork",
        header: "Art",
        cssClass: "_artwork-column",
      },
      {
        name: "quantity",
        header: "Qty",
        displayConstraint: "min_desktop",
        cssClass: "_quantity-column",
      },
      {
        name: "extras",
        header: "Extras",
        displayConstraint: "min_desktop",
        cssClass: "_extras-column",
      },
      {
        name: "alerts",
        header: "Alerts",
        displayConstraint: "min_tablet",
        cssClass: "_alerts-column",
      },
      {
        name: "factory",
        header: "Factory",
        isFilterable: true,
        isFilterFromList: true,
        filterKey: "factory",
        cssClass: "-production-cycle-column -hide-for-factory",
        displayConstraint: "min_tabletL",
      },
      {
        name: "dueDate",
        header: "Factory Due Date",
        isSortable: true,
        cssClass: "-production-cycle-column",
        //displayConstraint: 'min_desktop',
      },
      {
        name: "delay",
        header: "Delay",
        isSortable: true,
        displayConstraint: "min_desktop",
      },
      {
        name: "product",
        header: "Product",
        isFilterable: true,
        isFilterFromList: true,
        filterKey: "product",
        displayConstraint: "min_desktop",
      },
      {
        name: "reviewer",
        header: "Reviewer",
        isFilterable: true,
        isFilterFromList: true,
        filterKey: "reviewer",
        cssClass: "-quality-inspection-column -hide-for-factory",
        displayConstraint: "min_desktop",
      },
      {
        name: "responsibleUser",
        header: "Responsible User",
        isFilterable: true,
        isFilterFromList: true,
        filterKey: "responsibleUserId",
        cssClass: "-responsible-user-column -hide-for-factory",
        displayConstraint: "min_desktop",
      },
      {
        name: "notes",
        header: "Notes",
        cssClass: "-notes-column -hide-for-factory",
        displayConstraint: "min_desktop",
      },
      {
        name: "actions",
        header: "Action",
        cssClass: "_actions-column",
        displayConstraint: "min_tabletL",
      },
    ];

    this.fOptions = {
      filterByColumn: true,
      debounce: 1000,
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
      rowClassCallback: (row: TableRow) => this.resolveRowClass(row),
    };
  }

  get isProductionStatus(): boolean {
    return PlushieStatus.PRODUCTION_STATUSES.includes(this.statusId);
  }

  get isQualityInspectionStatus(): boolean {
    return [
      PlushieStatusValue.QUALITY_INSPECTION,
      PlushieStatusValue.PPS_INSPECTION,
      PlushieStatusValue.BULK_INSPECTION,
    ].includes(this.statusId);
  }

  get isFactoryView(): boolean {
    return this.getCurrentUser().role === AuthenticatedUser.ROLE_FACTORY;
  }

  get isSelectPageAvailable(): boolean {
    const nonSelectedRowsIds = this.fRowsIdsList.filter(
      (value) => !this.selectedPlushies.includes(value)
    );

    return nonSelectedRowsIds.length > 0;
  }

  get isSelectNoneAvailable(): boolean {
    return this.selectedPlushies.length > 0;
  }

  get isShowAllItemsAvailable(): boolean {
    return this.fShowSelectedItemsOnly;
  }

  get isShowSelectedItemsAvailable(): boolean {
    return this.selectedPlushies.length > 0 && !this.fShowSelectedItemsOnly;
  }

  public getThumbnail(artworkStorageItemId?: string): string | undefined {
    const storageItem = artworkStorageItemId
      ? this.fFileStorageDataStore.getItemById(artworkStorageItemId)
      : null;

    if (!storageItem) {
      return;
    }

    return this.fImageHandlerService.getThumbnailUrl(
      storageItem.timestampedUrl,
      PlushiesListPage.ARTWORK_SIZE,
      PlushiesListPage.ARTWORK_SIZE,
      true
    );
  }

  public clearSelection(): void {
    this.selectedPlushies = [];
    this.fLastSelectedItem = undefined;
  }

  public showAllItems(): void {
    this.fShowSelectedItemsOnly = false;

    if (!this.fTable) {
      return;
    }

    this.fTable.refresh();
  }

  public showSelectedItems(): void {
    this.fShowSelectedItemsOnly = true;

    if (!this.fTable) {
      return;
    }

    this.fTable.refresh();
  }

  public toggleItemSelection(id: string): void {
    const index = this.selectedPlushies.indexOf(id);

    if (index === -1) {
      this.selectedPlushies.push(id);
    } else {
      this.selectedPlushies.splice(index, 1);
    }
  }

  public selectPage(): void {
    this.selectedPlushies = Array.from(
      new Set([...this.selectedPlushies, ...this.fRowsIdsList])
    );
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    data.event.preventDefault();

    const itemId = data.row.id;

    if (!this.$screen.tabletL) {
      void this.$router.push(this.getItemUrl(itemId, "PlushieViewPage"));

      return;
    }

    const selection = document.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }

    if (!data.event.shiftKey) {
      this.toggleItemSelection(itemId);
      return;
    }

    if (!this.fLastSelectedItem) {
      this.toggleItemSelection(itemId);
      this.fLastSelectedItem = itemId;
      return;
    }

    const startIndex = this.fRowsIdsList.indexOf(this.fLastSelectedItem);
    const endIndex = this.fRowsIdsList.indexOf(itemId);

    const isSelect = this.selectedPlushies.includes(this.fLastSelectedItem);

    this.setSelectionForItems(startIndex, endIndex, isSelect);

    this.fLastSelectedItem = undefined;
  }

  public onMassActionProcessed(actionId: string): void {
    if (actionId === BulkExportAsExcelService.ID) {
      this.clearSelection();
      return;
    }

    this.refreshTable();
  }

  public onMassActionProcessingFinished(): void {
    this.fIsLoading = false;
  }

  public onMassActionProcessingStarted(): void {
    this.fIsLoading = true;
  }

  public onQueueControlAction(pulledPlushies: Plushie[]): void {
    this.fPulledToQueuePlushiesIds = pulledPlushies.map(
      (plushie) => plushie.id
    );

    this.refreshTable();
  }

  public onQueueControlClear(): void {
    this.fPulledToQueuePlushiesIds = [];

    this.refreshTable();
  }

  public toggleDisplayArtworks(): void {
    this.fUiConfigurationDataStore.togglePlushieListDisplayImages();
  }

  protected data(): Record<string, unknown> {
    return {
      fLastSelectedItem: this.fLastSelectedItem,
      fStatusName: this.fStatusName,
    };
  }

  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    if (this.fIsUpdatingTableParams) {
      return { data: [], count: 0 };
    }

    if (this.selectedPlushies.length === 0 && this.fShowSelectedItemsOnly) {
      this.fShowSelectedItemsOnly = false;
    }

    return this.isFactoryView
      ? this.fetchFactoryView(page, limit, filter, order)
      : this.fetchView(page, limit, filter, order);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  protected async created(): Promise<void> {
    void this.updateStatusName();

    this.fWindow.addEventListener("keyup", this.fKeyUpHandler);
  }

  protected destroyed(): void {
    this.fWindow.removeEventListener("keyup", this.fKeyUpHandler);
  }

  protected async init(): Promise<void> {
    if (!this.fOptions.listColumns) {
      return;
    }

    const order = this.getDefaultOrder();

    Vue.set(this.fOptions, "orderBy", order);

    const factoriesPromise = !this.isFactoryView
      ? this.fFactoryDataStore.loadFactories()
      : undefined;

    await Promise.all([
      factoriesPromise,
      this.fPlushieDataStore.loadProducts(),
      this.updateMassActionAvailability(),
    ]);

    const factories = this.fFactoryDataStore.factoriesList;
    const products = this.fPlushieDataStore.activeProductsList;

    const factoryOptions: ColumnFilterDropdownOption[] = [];

    factories.forEach((item) => {
      factoryOptions.push({ id: item.id, text: item.name });
    });

    const productOptions: ColumnFilterDropdownOption[] = [];

    products.forEach((item) => {
      productOptions.push({ id: item.id, text: item.name });
    });

    Vue.set(this.fOptions.listColumns, "factory", factoryOptions);
    Vue.set(this.fOptions.listColumns, "product", productOptions);

    if (this.isFactoryView) {
      return;
    }

    const users = await this.fAccountDataStore.loadUserInfosByMetaRoleIds([
      MetaRoleValue.ROLE_ADMIN,
      MetaRoleValue.ROLE_STORE,
    ]);

    const sortedUsers = users.sort((a, b) =>
      a.shortName.toLowerCase() > b.shortName.toLowerCase() ? 1 : -1
    );

    const userOptions: ColumnFilterDropdownOption[] = [];
    sortedUsers.forEach((user) => {
      userOptions.push({ id: user.id, text: user.shortName });
    });

    Vue.set(this.fOptions.listColumns, "reviewer", userOptions);
    Vue.set(this.fOptions.listColumns, "responsibleUser", userOptions);

    return Promise.resolve();
  }

  protected getExtraParams(): Dictionary<ApiFilterValue> {
    const params: Dictionary<ApiFilterValue> = {
      status: this.statusId,
    };

    if (
      this.statusId === PlushieStatusValue.IN_DESIGN &&
      this.fPulledToQueuePlushiesIds
    ) {
      params.id = this.fPulledToQueuePlushiesIds;
    }

    if (this.fShowSelectedItemsOnly) {
      params.id = this.selectedPlushies;
    }

    if (this.reviewer !== undefined) {
      params.reviewer = this.reviewer;
    }

    if (this.responsibleUser !== undefined) {
      params.responsibleUserId = this.responsibleUser;
    }

    return params;
  }

  protected buildOrderFieldsList(
    order: QueryOrderParameter
  ): QueryOrderParameter {
    const newOrder = order.concat([["id", FieldOrderAsc]]);

    if ([PlushieStatusValue.SENT_TO_CUSTOMER].includes(this.statusId)) {
      return order;
    }

    const orderPrefix: QueryOrderParameter = [["priority", FieldOrderDesc]];

    return orderPrefix.concat(newOrder);
  }

  private async fetchView(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    const [plushiesCollection] = await Promise.all([
      this.fPlushieSummaryRepository.getList(page, limit, filter, order),
      this.fPlushieDataStore.loadProducts(),
      this.fFactoryDataStore.loadFactories(),
      this.fPlushieDataStore.loadUpgrades(),
    ]);

    const plushies = plushiesCollection.getItems();

    const plushieIds: string[] = [];
    const relatedUserIds: string[] = [];
    plushies.forEach((plushie: PlushieSummary) => {
      plushieIds.push(plushie.id);

      if (plushie.reviewer && !relatedUserIds.includes(plushie.reviewer)) {
        relatedUserIds.push(plushie.reviewer);
      }

      if (
        plushie.responsibleUser &&
        !relatedUserIds.includes(plushie.responsibleUser)
      ) {
        relatedUserIds.push(plushie.responsibleUser);
      }
    });

    const [plushieAlerts, relatedUsers] = await Promise.all([
      this.fAlertDataStore.loadPlushieAlertsByPlushieIds({
        plushieIds,
        useCache: false,
      }),
      this.fAccountDataStore.loadUserInfosByIds(relatedUserIds),
    ]);

    const alertIds: string[] = [];

    Object.keys(plushieAlerts).forEach((plushieId: string) => {
      const plushieAlertIds = plushieAlerts[plushieId];

      plushieAlertIds.forEach((alertId) => {
        if (!alertIds.includes(alertId)) {
          alertIds.push(alertId);
        }
      });
    });

    const alerts = await this.fAlertDataStore.loadPlushieAlertsByIds(alertIds);

    const alertIconStorageItemIds: string[] = [];

    Object.keys(alerts).forEach((alertId: string) => {
      const alert = alerts[alertId];

      if (!alert) {
        return;
      }

      alertIconStorageItemIds.push(alert.iconStorageId);
    });

    const artworkStorageItemIds: string[] = [];

    plushies.forEach((plushie) => {
      if (plushie.mainArtworkStorageItemId) {
        artworkStorageItemIds.push(plushie.mainArtworkStorageItemId);
      }
    });

    await this.fFileStorageDataStore.loadItemsByIds([
      ...artworkStorageItemIds,
      ...alertIconStorageItemIds,
    ]);

    const result: TableRow[] = [];

    this.fRowsIdsList = [];

    plushies.forEach((plushie) => {
      const product = this.fPlushieDataStore.getProductById(plushie.product);
      const factory = plushie.factory
        ? this.fFactoryDataStore.getFactoryById(plushie.factory)
        : null;

      const reviewer = plushie.reviewer ? relatedUsers[plushie.reviewer] : null;
      const responsibleUser = plushie.responsibleUser
        ? relatedUsers[plushie.responsibleUser]
        : null;

      const upgrades: string[] = [];

      plushie.upgrades.forEach((upgradeId) => {
        const upgrade = this.fPlushieDataStore.getUpgradeById(upgradeId);

        if (upgrade && upgrade.shortName) {
          upgrades.push(upgrade.shortName);
        }
      });

      this.fRowsIdsList.push(plushie.id);

      result.push({
        id: plushie.id,
        storeItemId: plushie.storeItemId,
        orderNumber: plushie.orderNumber ? plushie.orderNumber : "",
        orderDate: plushie.orderDate
          ? plushie.orderDate.toLocaleDateString()
          : "",
        artwork: plushie.mainArtworkStorageItemId,
        quantity: plushie.quantity,
        extras: upgrades.join(", "),
        factory: factory ? factory.name : "",
        dueDate: plushie.dueDate ? plushie.dueDate.toLocaleDateString() : "",
        delay: plushie.delay ? plushie.delay.toString() : "",
        product: product ? product.name : "",
        productId: product ? product.id : "",
        priority: plushie.priority,
        reviewer: reviewer ? reviewer.shortName : "",
        responsibleUser: responsibleUser ? responsibleUser.shortName : "",
        notes: plushie.notes ? truncate(plushie.notes, 15) : "",
      });
    });

    return { data: result, count: plushiesCollection.totalItemsCount };
  }

  private async fetchFactoryView(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    const [plushiesCollection] = await Promise.all([
      this.fPlushieFactorySummaryRepository.getList(page, limit, filter, order),
      this.fPlushieDataStore.loadProducts(),
      this.fPlushieDataStore.loadUpgrades(),
    ]);

    const plushies = plushiesCollection.getItems();

    const plushieIds = plushies.map((plushie) => plushie.id);

    const plushieAlerts = await this.fAlertDataStore.loadPlushieAlertsByPlushieIds(
      { plushieIds, useCache: false }
    );

    const alertIds: string[] = [];

    Object.keys(plushieAlerts).forEach((plushieId: string) => {
      const plushieAlertIds = plushieAlerts[plushieId];

      plushieAlertIds.forEach((alertId) => {
        if (!alertIds.includes(alertId)) {
          alertIds.push(alertId);
        }
      });
    });

    const alerts = await this.fAlertDataStore.loadPlushieAlertsByIds(alertIds);

    const alertIconStorageItemIds: string[] = [];

    Object.keys(alerts).forEach((alertId: string) => {
      const alert = alerts[alertId];

      if (!alert) {
        return;
      }

      alertIconStorageItemIds.push(alert.iconStorageId);
    });

    const artworkStorageItemIds: string[] = [];

    plushies.forEach((plushie) => {
      if (plushie.mainArtworkStorageItemId) {
        artworkStorageItemIds.push(plushie.mainArtworkStorageItemId);
      }
    });

    await this.fFileStorageDataStore.loadItemsByIds([
      ...artworkStorageItemIds,
      ...alertIconStorageItemIds,
    ]);

    const result: TableRow[] = [];
    this.fRowsIdsList = [];

    plushies.forEach((plushie) => {
      const product = this.fPlushieDataStore.getProductById(plushie.product);

      const upgrades: string[] = [];
      plushie.upgrades.forEach((upgradeId) => {
        const upgrade = this.fPlushieDataStore.getUpgradeById(upgradeId);

        if (upgrade && upgrade.shortName) {
          upgrades.push(upgrade.shortName);
        }
      });

      this.fRowsIdsList.push(plushie.id);

      result.push({
        id: plushie.id,
        storeItemId: plushie.storeItemId,
        orderNumber: "",
        orderDate: "",
        artwork: plushie.mainArtworkStorageItemId,
        quantity: plushie.quantity,
        extras: upgrades.join(", "),
        factory: "",
        dueDate: plushie.dueDate ? plushie.dueDate.toLocaleString() : "",
        delay: plushie.delay ? plushie.delay.toString() : "",
        product: product ? product.name : "",
        productId: product ? product.id : "",
        priority: plushie.priority,
      });
    });

    return { data: result, count: plushiesCollection.totalItemsCount };
  }

  private refreshTable(): void {
    this.clearSelection();
    this.fShowSelectedItemsOnly = false;

    if (!this.fTable) {
      return;
    }

    this.fTable.refresh();
  }

  private resolveRowClass(row: TableRow): string | void {
    if (row.productId === ProductValue.BULK_SAMPLE) {
      return "-bulk-sample";
    }

    if (row.priority > 1) {
      return "-high-priority";
    }
  }

  private getDefaultOrder(): { column: string; ascending: boolean } {
    const column = this.isFactoryView ? "dueDate" : "createdAt";

    const isAscDirection = ![PlushieStatusValue.SENT_TO_CUSTOMER].includes(
      this.statusId
    );

    return {
      column: column,
      ascending: isAscDirection,
    };
  }

  private setDefaultOrder(): void {
    if (!this.fTable) {
      return;
    }

    const order = this.getDefaultOrder();

    this.fTable.setOrder(order.column, order.ascending);
  }

  private setSelectionForItems(
    startIndex: number,
    endIndex: number,
    isSelected: boolean
  ): void {
    if (endIndex < startIndex) {
      [startIndex, endIndex] = [endIndex - 1, startIndex - 1];
    }

    const itemsToToggle = this.fRowsIdsList.slice(startIndex + 1, endIndex + 1);

    itemsToToggle.forEach((id) => {
      const index = this.selectedPlushies.indexOf(id);

      if (isSelected && index === -1) {
        this.selectedPlushies.push(id);
      }

      if (!isSelected && index !== -1) {
        this.selectedPlushies.splice(index, 1);
      }
    });
  }

  private async updateStatusName() {
    const status = await this.fPlushieDataStore.loadPlushieStatusById(
      this.statusId
    );

    if (status) {
      this.fStatusName = status.name;
    }
  }

  private onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 16 || event.charCode === 16) {
      this.fLastSelectedItem = undefined;
    }
  }

  private async updateMassActionAvailability(): Promise<void> {
    const availableMassActions = await this.fAvailableMassActionsProvider.provide(
      this.statusId
    );

    this.fIsMassActionAvailable = availableMassActions.length > 0;
  }

  private fKeyUpHandler = (e: KeyboardEvent) => this.onKeyUp(e);

  private getCurrentUser(): AuthenticatedUser {
    const user = this.fUserProvider.getUser();

    if (!user) {
      throw new Error("The current user is not found");
    }

    return user;
  }

  @Watch("statusId", { immediate: false })
  @Watch("reviewer", { immediate: false })
  @Watch("responsibleUser", { immediate: false })
  private _onStatusIdOrRelatedUserChange() {
    this.clearSelection();

    if (!this.fTable) {
      return;
    }

    this.fIsUpdatingTableParams = true;

    this.fTable.resetQuery();
    this.setDefaultOrder();

    this.fIsUpdatingTableParams = false;

    void this.updateStatusName();

    this.refreshTable();

    void this.updateMassActionAvailability();
  }
}
