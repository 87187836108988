













import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import FileStorageStore from "@/modules/file-storage/store";
import store from "@/store";

import ListItem from "./ListItem.vue";

import PlushieStore from "../../store";
import PlushieAttachment from "../../plushie-attachment.model";

@Component({
  components: { ListItem },
})
export default class PlushieAttachmentsListView extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  private fFileStorageStore!: FileStorageStore;
  private fPlushieStore!: PlushieStore;

  private fIsDataLoading = true;

  get attachments(): PlushieAttachment[] {
    return this.fPlushieStore.getAttachmentsByPlushieId(this.plushieId);
  }

  get showLoadingIndicator(): boolean {
    return this.fIsDataLoading;
  }

  constructor() {
    super();
    this.fFileStorageStore = getModule(FileStorageStore, store);
    this.fPlushieStore = getModule(PlushieStore, store);
  }

  private async loadData(): Promise<void> {
    this.fIsDataLoading = true;

    const attachments = await this.fPlushieStore.loadAttachmentsByPlushieId({
      plushieId: this.plushieId,
      useCache: false,
    });

    const attachmentTypesIdsSet: Set<string> = new Set();
    const storageItemsIds: string[] = [];

    attachments.forEach((attachment) => {
      attachmentTypesIdsSet.add(attachment.type);
      storageItemsIds.push(attachment.storageItem);
    });

    await Promise.all([
      this.fPlushieStore.loadAttachmentTypesByIds({
        ids: Array.from(attachmentTypesIdsSet),
      }),
      this.fFileStorageStore.loadItemsByIds(storageItemsIds),
    ]);

    this.fIsDataLoading = false;
  }

  @Watch("plushieId", { immediate: true })
  private _onPlushieIdChanged() {
    void this.loadData();
  }
}
